<template>
  <el-row class="login-layout">
    <div class="login-content">
      <div class="main">
        <div class="logo">
          <img src="@/assets/images/logo.png" alt />
        </div>
        <router-view></router-view>
      </div>
    </div>
    <footer class="footer-div">
      <span>Copyright© <a href="https://beian.miit.gov.cn/">沪ICP备15025555号</a></span>
    </footer>
    <div class="login-images">
      <div class="images-box">
        <i-image></i-image>
      </div>
    </div>
  </el-row>
</template>

<script>
import iImage from "./module/images.vue";
export default {
  components: {
    iImage
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.login-layout,
.login-content,
.login-images,
.images-box {
  height: 100vh;
  overflow: hidden;
}
.login-content {
  position: relative;
  float: left;
  margin-right: -600px;
  width: 600px;
  .main {
    position: absolute;
    top: 50%;
    margin-top: -300px;
    left: 50%;
    margin-left: -200px;
    height: 600px;
    width: 400px;
    .logo {
      width: 225px;
      img {
        width: 100%;
      }
    }
  }
}

.footer-div{
  margin: 0 auto;
  position:fixed;
  z-index:999;
  left:0; 
  bottom:0;
  height: 49px;
  text-align: center;
  width: 100%;
  font-size: 12px;
  color: hsla(0,0%,100%,.6);
  line-height: 49px;
  background: #181f31;
}

.login-images {
  float: right;
  width: 100%;
  .images-box {
    margin-left: 550px;
  }
}
</style>
